<template>
  <div class="main_container">
    <Terms />
    <Plan />
  </div>
</template>

<script>
// @ is an alias to /src
import Terms from "@/components/terms/Terms.vue";
import Plan from "@/components/home/Plan.vue";

export default {
  name: "Home",
  components: {
    Terms,
    Plan
  },
};
</script>
